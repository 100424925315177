

body{background-color: #EDF1F3; color: #222; }
img{max-width: 100% }
figure{max-width: 100% !important; }

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.react-icons {
  vertical-align: middle;
  height: 2.5em;
}

.divider {
  border-color: #000;
  border-style: solid;
  border-width: 0 0 1px;
  height: 10px;
  line-height: 20px;
  text-align:center;
  overflow: visible;
}

.divider span {
  background-color: #FFF;
  display: inline-block;

  min-height: 20px;
  min-width: 10%;
}

.App-link {
  color: #61dafb;
}

.table_link {
  display: flex;
  justifyContent: center;
  alignItems: center;
}

.table_results_header {
  display: flex;
  justifyContent: space-between;
  alignItems: center;
}

.table_results_right_widget {
  display: flex;
  justifyContent: flex-end;
  alignItems: center;
}

.table_results_gephi_button {
  textAlign: right;
  marginLeft: 10;
}

.table_results_csv_button {
  textAlign: right;
  marginLeft: 10;
}

.table_results_card_table {
/*  overflowY: scroll;
  height: 30rem;*/
  padding: 10px;
}

.table_results_font_size {
  fontSize: 12px;
}

/*.table_results_card_body {
  overflowY: scroll;
  height: 35rem;
}*/

#home-blurb{ background-color: #d2a74d; color: #fff; }
  #home-blurb p{ color: #fff; font-size: 1em; }
  #home-blurb a{ color: #fff; font-weight: bold; text-decoration: underline;}
