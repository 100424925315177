.table_link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_results_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_results_right_widget {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table_results_gephi_button {
  width: 250px;
  font-size: 10px;
  text-align: right;
  margin-left: 10px;
}

.table_results_csv_button {
  width: 100px;
  text-align: right;
  margin-left: 10px;
}

.table_results_card_table {
  /* overflow-y: scroll; */
  /* height: 42rem; */
  padding: 10px;
}

.table_no_results_card_table {
    padding: 10px;
}

.loading_card {
    padding: 10px;
    height: 5rem;
}

.table_results_font_size {
    font-size: 12px;
    padding: 0.5px;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 5px;
    margin-bottom: 0px;
}

.refine_checkbox_wrapper{
    font-size: 12px;
    margin-left: 10px;
}
/*
.table_results_card_body {
  overflow-y: scroll;
  height: 39rem;
}*/

.refine_checkbox {
    margin: 0px 10px 0px 10px;
    padding: 0px;
}

.refine_form {
    padding: 0px;
}

.tableRow {
    margin: auto;
    flex-direction: row;
}

.tableCol {
    margin: auto;
}

.refine_checkbox_card_body {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 35rem;
  padding: 1px;
}



.refine_card {
  width: 19rem;
  padding: 0.1rem;
}

.refine_buttons {
  text-align: right;
}

.refine_checkbox_card_title {
    padding-top: 1rem;
}

.refine_checkbox_card{
  overflow-y: scroll;
  height: 12rem;
  width: 15rem;
  padding: 1px;
}

.search_card_div {
    height: auto;
}

.search_basic_textfield {
    width: 100%;
}

.search_basic_submit_form {
    text-align: right;
    align-items: right;
}

.search_basic_submit_button {
    text-align: right;
}

.search_basic_advanced_form {
    text-align: left;
}

.search_basic_advanced_button {
    text-align: left;
}

.search_advanced_person_link {
    text-decoration: underline;
}

.search_advanced_reference_textfield {
    width: 100%;
    height: 100%;
}

.search_advanced_field_table {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.search_advanced_field_form {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.search_advanced_field_button {
    background-color: #aee090;
}

.search_advanced_search_complete_button {
    background-color: #c4e0af;
}

.search_advanced_search_reset_button {
    background-color: white;
}

.search_autocomplete_list_group {
    overflow-y: scroll;
    height: 200px;
}

.search_autocomplete_list_form {
    width: 100%;
}

.search_autocomplete_list_textfield {
    width: 100%;
}

.reception_typography {
    display: inline;
    margin-left: 10px;
}

.reception_root {
    flex-grow: 1;
    overflow: hidden;
    background-size: cover;
    background: #d4d8dd 0 400px;
    margin-top: 20px;
    padding: 20px 20px 200px;
}

.grid {
    width: 1000px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.gridMain {
    width: 1300px;
    padding-bottom: 30px;
}

.gridRight {
    padding: 20px;
    width: 1000px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inliningBlock {
    display: block;
    margin-right: 10px;
    margin-left: 25px
}

.inliningRight {
    display: inline;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 10px;
    margin-left: 35px;
}

.reception_typography {
    display: inline;
    margin-left:5px;
    padding:5px;
}

.reception_typography_works {
    display: inline;
    margin-left:10px;
    padding:10px;
}

.reception_typography_headings {
    padding:20px;
}

.citation_button {
    width:120px;
    justify-content: center;
    text-align: center;
}

.reception_link {
    padding: 10px;
}

.reception_card_body {
    padding: 20px;
    background-color: #f0f3f7;
}

.reception_grid_body {
    padding: 20px;
    border-width: 0px;
}

.reception_grid_card_body {
    padding: 20px;
}


.reception_received_grid_body {
    padding: 20px;
    justify-content: center;
    text-align: center;
    border-width: 0px;
}

.reception_attributes_grid_body {
    /* padding: 100px; */
    border-width: 0px;
    font-size: 0.8rem;
}

.reception_arrow{
    padding: 0;
    margin: 0 auto;
}

.reception_author_grid_body {
    border: 1px black;
}

.reception_explore_space {
    align-items: center;
}

.reception_image_text {
    line-height: 150%;
}

.reception_image_text_large {
    line-height: 200%;
}

.reception_image_card_header {
    display: flex;
}

.reception_image_card_body {
    background-color: #f0f3f7;
}

.reception_captured_card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reception_captured_card_body {
    height: 270px;
    padding: 10px;
}

.person_details_table {
    width: 250px;
    font-weight: bold;
}

.person_details_table_first_name {
    width: 150px;
    font-weight: bold;
}

.person_works_card {
    /* height: 100px; */
    padding: 10px;
}

.source_works_detail_table {
    width: 400px;
    font-weight: bold;
}

.card_sources_captured {
    /* height: 270px; */
    padding: 10px;
}

.work_search_card {
    text-align: center;
    padding-top: 20px;
}

.search_button_link {
    background: none!important;
    border: none;
    padding: 0!important;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.pagination {
    margin-top: 25px;
}
.pagination span {
    cursor: pointer;
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
}

.pagination span.active {
    background-color: #0099FF;
    color: white;
    border: 1px solid #0099FF;
}

#edgeFilterList, #graphLegend{padding: 1em 0.5em; background: rgba(250, 250, 250, 0.9); border: 1px solid #dbdbdb; box-shadow: -2px 2px #eee; font-size: .9em;}
#edgeFilterList ul{margin: 0 1em; padding: 0; }
#edgeFilterList h5{font-size: 1em;}
#edgeFilterList li button{padding: 0; font-size: .8em;}

#graphLegend{padding: 0.5em;}
#graphLegend ul{padding: 0; margin: 0;}
#graphLegend li{font-size: .8em;}

.popover{ font-size: .8em; padding: 2px;}
    .node-popover{}
    .edge-popover{}

.carousel-control-next-icon, .carousel-control-prev-icon{ background-color: #d2a74d; border-radius: 25px; width: 25px; height: 25px;}
.carousel-indicators li{background-color: #d2a74d; height: 6px;}


#aboutDataWrapper{background: #A4A397 url('./images/bg-about-data.png') no-repeat 50% 50%; min-height: 270px; text-align: center; padding: 5px 0 0;}
    #aboutDataWrapper h4{font-size: 1.75em; color: #F1F7FA}
    #aboutDataWrapper a{color: #F1F7FA; font-size: 1.3em;;}
    #aboutDataWrapper .aboutDataItem{ display: inline-block; width: 175px; text-align: center; }
    #aboutDataWrapper .aboutDataItem span.roundedData{ color: #fff; font-size: 38px; display: block; border-radius: 50%; background: #D2A74D; border: 1px solid #F1F7FA; padding: 5px; width: 75px; height: 75px; margin: 0 auto 2px;}
    #aboutDataWrapper .aboutDataItem span.aboutDataFigure, #aboutDataWrapper .aboutDataItem span.aboutDataTitle{  color: #533530; display: block;;}
    #aboutDataWrapper .aboutDataItem span.aboutDataFigure{font-size: 28px; font-family: "Crimson Text", Georgia, serif;}
    #aboutDataWrapper .aboutDataItem span.aboutDataTitle{ font-size: 14px;}

.homeExploreBanner{ background: url('./images/recirc-home-explore.png') no-repeat 50% 50%; min-height: 520px; padding-top: 20px; }
.homeExploreBanner h2, .homeExploreBanner p{ background-color: rgba(68, 16, 16, 0.7); color: #fff; padding: 1em; margin: 2em; font-family: "Crimson Text", Georgia, serif; text-align: center;}
.homeExploreBanner a, .homeExploreBanner a:link, .homeExploreBanner a:visited, .homeExploreBanner a:hover, .homeExploreBanner a:active {color: #fff; text-decoration: none;;}
.homeExploreBanner h2{  margin: 50px auto; width: 84%; font-size: 2.5em; padding: .125em 1em;}
.homeExploreBanner p{  margin: 120px auto 20px; width: 70%; font-size: 1.5em; padding: .125em .75em;}
.homeExploreBanner a#exploreHomeBtn{ background-color: rgba(68, 16, 16, 0.7); color: #fff; padding: 1em; margin: 2em; font-family: "Crimson Text", Georgia, serif; text-align: center; font-style: italic; border: 1px solid #fff; border-radius: 3px; font-size: 1.25em; padding: .25em .75em; margin: 0 auto; display: block; width: 40%; text-decoration: none;}
    .homeExploreBanner a#exploreHomeBtn:hover, .homeExploreBanner a#exploreHomeBtn:active, .homeExploreBanner a#exploreHomeBtn:focus{background-color: #53352f; text-decoration: none; color: #fff; border-color: #d2a74d;}


.pageSubtitle{font-size: 1.75em; font-family: "Crimson Text", Georgia, serif; text-align: center; line-height: 1.125em;}

#exploreInternalNav{text-align: center; border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb; padding: 10px 0; margin: 10px 0;}
.explore{padding: 3em 0;  }
    .explore h2{font-size: 48px; margin: 1em 0 0;}
    .explore h6{font-family: CrimsonText-Regular; font-size: 28px; line-height: 28px; }
    .explore img{border: 5px solid #FFFFFF; box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.50);}    
.explore a, .explore a:link, .explore a:hover, .explore a:active, .explore a:visited{padding: 1em; color: inherit; text-decoration: none;}

a.exploreHomeBtn{background:  #53352f; color: #fff; font-family: "Crimson Text", Georgia, serif; font-style: italic; border: 1px solid #fff; border-radius: 3px; font-size: 1.25em; padding: .25em .75em; display: inline-block; text-decoration: none; margin-top: 1em;}
    a.exploreHomeBtn:hover, a.exploreHomeBtn:visited, a.exploreHomeBtn:active, a.exploreHomeBtn:focus{background-color: #53352f; text-decoration: none; color: #fff; border-color: #d2a74d;}

.exploreHighlight{background: #6E463E; color: #fff; margin: 2em 0;}
    .exploreHighlight .container{background: url('./images/explore-graph.png') no-repeat 0 100%; }
    .exploreHighlight:last-child{ margin: 2em 0 0;}
    .exploreHighlight a, .exploreHighlight h2, .exploreHighlight h6, .exploreHighlight p{color: #fff;}    
    .exploreHighlight a:hover, .exploreHighlight a:active{ background: inherit; color: #fff; }



.blogPost blockquote{padding-left: 3em; padding-right: 3em;}
.blogPost figure.alignright, .blogPost figure.align {display: inline-block }
.blogPost .alignright{float:right; margin: 0 0 1em 1em; }
.blogPost .alignleft{float:left; margin: 0 1em 1em 0;  }